import React from 'react'
import PropTypes from 'prop-types'
import { Box, Flex, Text, Grid } from 'theme-ui'
import { motion } from 'framer-motion'
import FormattedPrice from '../FormattedPrice'

const MotionBox = motion(Box)

const SummaryItem = ({ bold, term, children }) => (
  <Flex>
    <Box sx={{ flex: '1 1' }}>
      <Text sx={{ fontSize: 1, fontWeight: bold && 'heading' }}>{term}</Text>
    </Box>
    <Box sx={{ flex: 1 }}>
      <Text
        as="p"
        sx={{ fontSize: 1, fontWeight: bold && 'heading', textAlign: 'right' }}
      >
        {children}
      </Text>
    </Box>
  </Flex>
)

export const OrderSummary = ({
  loading,
  subtotalPriceV2,
  requiresShipping,
  totalPriceV2,
  note,
}) => (
  <MotionBox p={4} animate={{ opacity: loading ? 0.5 : 1 }}>
    <Grid sx={{ gridAutoFlow: 'row', gap: 2 }}>
      {note && <SummaryItem term="order note">{note}</SummaryItem>}
      <SummaryItem term="subtotal">
        {subtotalPriceV2 && <FormattedPrice priceV2={subtotalPriceV2} />}
      </SummaryItem>
      {requiresShipping && (
        <SummaryItem term="estimated shipping">-</SummaryItem>
      )}
      <SummaryItem term="estimated total" bold>
        {totalPriceV2 && <FormattedPrice priceV2={totalPriceV2} />}
      </SummaryItem>
    </Grid>
  </MotionBox>
)
OrderSummary.propTypes = {
  loading: PropTypes.bool.isRequired,
  subtotalPriceV2: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }).isRequired,
  totalPriceV2: PropTypes.shape({
    amount: PropTypes.string.isRequired,
    currencyCode: PropTypes.string.isRequired,
  }).isRequired,
  shippingPriceV2: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
  }),
  requiresShipping: PropTypes.bool.isRequired,
}
OrderSummary.defaultProps = { shippingPriceV2: null }
export default OrderSummary
