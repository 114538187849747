import dayjs from 'dayjs'
import { motion, AnimatePresence } from 'framer-motion'
import { graphql, useStaticQuery, Link as GatsbyLink } from 'gatsby'
import React from 'react'
import { Box, Flex, Text } from 'theme-ui'
import { usePageContext } from '../contexts/PageContext'

const Countdown = () => {
  const { isBeloved } = usePageContext()
  const data = useStaticQuery(graphql`
    {
      sanitySiteAnnouncements {
        countdown {
          text
          startDate
          stopDate
          path
        }
      }
    }
  `)

  const { text, startDate, stopDate, path } =
    data.sanitySiteAnnouncements.countdown || {}
  if (isBeloved || !text || !startDate || !stopDate) return null

  const tooEarly = dayjs().isBefore(startDate, 'day')
  const difference = dayjs(stopDate).diff(dayjs(), 'day', true)
  const days = Math.ceil(difference)

  if (days < 1 || tooEarly) return null

  const message = text.replace('#days', `${days} day${days > 1 ? 's' : ''}`)
  const MotionBox = motion(Flex)

  return (
    <AnimatePresence>
      <Box
        as={path ? GatsbyLink : Box}
        to={path}
        sx={{
          width: '100vw',
          maxWidth: '100%',
          height: 35,
          position: 'relative',
          overflow: 'hidden',
          color: 'primary',
        }}
      >
        <MotionBox
          sx={{
            position: 'absolute',
            whiteSpace: 'nowrap',
          }}
          animate={{
            x: [0, -1035],
            transition: {
              x: {
                repeat: Infinity,
                repeatType: 'loop',
                duration: 50,
                ease: 'linear',
              },
            },
          }}
        >
          {[...Array(20).keys()].map(k => (
            <Box
              key={`ticker-${k}`}
              sx={{
                bg: 'cream',
                fontWeight: 'bold',
                letterSpacing: 'wider',
                span: { fontSize: ['8px', 0] },
              }}
              py={2}
            >
              •<Text px={4}>{message}</Text>
            </Box>
          ))}
        </MotionBox>
      </Box>
    </AnimatePresence>
  )
}

export default Countdown
