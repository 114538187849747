export const orgLdJSON = `
{
    "@context": "https://schema.org",
    "@type": "Organization",
    "name": "bluboho",
    "url": "https://www.bluboho.com",
    "description": "tell your story with our handcrafted jewelry. raw and refined pieces to mark every moment. international shipping. free shipping within Canada and the US. gift cards available.",
    "logo": "https://cdn.shopify.com/s/files/1/0685/0359/t/32/assets/bluboho-logo-vector-black.svg?v=4731259283198669391",
    "image": "https://cdn.shopify.com/s/files/1/0685/0359/t/32/assets/bluboho-logo-vector-black.svg?v=4731259283198669391",
    "sameAs": ["https://www.facebook.com/blubohojewelry","https://www.instagram.com/bluboho/","https://www.pinterest.ca/bluboho/"],
    "address": {
        "@type": "PostalAddress",
        "streetAddress": "181 carlaw avenue, 303",
        "addressLocality": "toronto",
        "addressRegion": "Ontario",
        "postalCode": "M4M 2S1",
        "addressCountry": "Canada"
    },
    "founder": [
      {
        "@type": "Person",
        "name":"Maggie Aurocco",
        "knowsAbout":"Dainty Jewelry"
      },
      {
        "@type": "Person",
        "name":"Cheryl Labbett",
        "knowsAbout":"Designer Jewelry"
      }
    ],
    "telephone": "6472736297"
}
`
